import { ListNoBullets } from 'site/src/components/atm.list-no-bullets/ListNoBullets.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from 'site/src/context/client-doctor-select'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getClientAndDoctorUrl, normalizeUrl } from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import { getListJsonLd } from 'site/src/components/legacy/mol.seo/build-list-json-ld'
import { MediaCell } from 'site/src/components/mol.media-cell/media-cell.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { Col, Divisor, Grid, Row, Separator } from 'atomic'
import { graphql } from 'gatsby'
import React from 'react'

interface DifferentiatedServicesItem {
  title: string
  url: string
  thumbnailImgixUrl: string
  imgAlt: string
  description: string
}

export interface DifferentiatedServicesTemplateProps {
  list: DifferentiatedServicesItem[]
}

const DifferentiatedServicesTemplate: React.FunctionComponent<PageProps<
  DifferentiatedServicesTemplateProps
>> = props => {
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
  const siteUrl = props.data.site.siteMetadata.siteUrl
  const seoData = props.data.cosmicjsInformacoesEstaticas.metadata.seo

  return (
    <ClientDoctorSelectContext.Provider
      value={{ clientUrl: clientPath, doctorUrl: doctorPath }}
    >
      <IndexLayout location={props.location}>
        <SEO
          jsonld={getListJsonLd(
            props.pageContext.list.map(item => ({
              url: normalizeUrl(`${siteUrl}${item.url}`)
            }))
          )}
          socialMedia={{
            canonicalUrl: getCanonicalUrl(
              siteUrl,
              appPaths.differentiatedServices.path
            ),
            title: seoData.titulo,
            image: seoData.imagem.url,
            imageAlt: seoData.imagemAlternativa,
            description: seoData.descricao
          }}
        />
        <ListNoBullets id='list-differentiated-services'>
          <Grid>
            <TitleWithBreadcrumbRow title='Serviços diferenciados' />
          </Grid>

          <ServiceListGrid pageContext={props.pageContext} />
        </ListNoBullets>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default DifferentiatedServicesTemplate

interface ServiceListGridProps {
  pageContext: DifferentiatedServicesTemplateProps
}

const ServiceListGrid: React.FunctionComponent<ServiceListGridProps> = props => (
  <Grid>
    <Row mb expanded>
      {props.pageContext.list &&
        props.pageContext.list.map(item => (
          <Col xs={12}>
            <Row mb>
              <Col xs={12}>
                <React.Fragment key={item.title}>
                  <Separator />
                  <MediaCell
                    imgSrc={item.thumbnailImgixUrl}
                    title={item.title}
                    imgAlt={item.imgAlt}
                    content={item.description}
                    to={item.url}
                    readMoreButton='Saiba mais'
                    id='differentiated-services'
                  />
                  <Separator />
                  <Divisor />
                </React.Fragment>
              </Col>
            </Row>
          </Col>
        ))}
    </Row>
    <LargeSeparatorRow />
  </Grid>
)

export const query = graphql`
  query DifferentiatedServicesList {
    site {
      siteMetadata {
        siteUrl
      }
    }
    cosmicjsInformacoesEstaticas(slug: { eq: "servicos-diferenciados-lista" }) {
      metadata {
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            imgix_url
            url
          }
        }
      }
    }
  }
`
